import { Section } from "../components/Section";


export const MeetTestimonials = (props: {

}) => {
  return (
    <Section className="meet-testimonials-section" morePadding={true}>
      <h4 className="subtitle gothic" style={{ textAlign: 'center' }}>
        When I first meet clients they tell me...
      </h4>
      <div
        className="monials-grid"
      >
        <Block>
          "I don't even know if it's worth trying anymore. We <b>can't even talk</b> without arguing."
        </Block>
        <Block>
          "I'm <b>out of control</b> with food. I'm addicted to sugar and carbs. I have to get my eating under control."
        </Block>
        <Block>
          "I'm not sure it's possible for me to take care of myself. I've <b>failed</b> so many times."
        </Block>
        <Block>
          "I'm a strong, confident woman. I shouldn't feel this way about my body...
          But I do.
          <br></br>
          <b>I hate how I look</b>."</Block>
        <Block>
          "If people knew what I'm really like, they would have <b>no respect</b> for me at all."        </Block>
        <Block>
          "I've tried cutting out gluten, FODMAPS, sugar... Nothing makes my <b>stomach feel better</b>."
        </Block>
      </div>
    </Section>
  );
};


const Block = (props: any) => {
  return (
    <div className="monial-cell">
      <p>
        {props.children}
      </p>
    </div>
  );
};