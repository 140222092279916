import './App.css';
import {
  Routes,
  Route,
} from "react-router-dom";

import { HeaderBar } from './components/HeaderBar';
import { Home } from './pages/Home';
import { CapacityTesting } from './pages/services/CapacityTesting';
import { FindingCenterApp } from './pages/services/FindingCenterApp';
import { Therapy } from './pages/services/Therapy';
import { WorkplaceTraining } from './pages/services/WorkplaceTraining';

function App() {
  return (
    <div className="App">
      <HeaderBar />
      <div style={{
        paddingTop: 110
      }}>
        <Routes>
          <Route path="/services/therapy" element={<Therapy />} />
          <Route path="/services/capacity-testing" element={<CapacityTesting />} />
          <Route path="/services/workplace-training" element={<WorkplaceTraining />} />
          <Route path="/services/finding-center-app" element={<FindingCenterApp />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </div >
  );
}

export default App;
