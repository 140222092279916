
import profilePhoto from "../imgs/new/about-me-profile.webp";
import { Section } from "../components/Section";

export const AboutMe = () => {
  return (
    <Section className="about-me-section" morePadding={true} id="about">
      <div
        className="photo-and-description"
      >
        <div className='hi-there-profile'>
          <h2>Hi, there!</h2>
          <h3>I'm Dr. Stephanie Smith</h3>
          <div className="photo" style={{ marginTop: 10}}>
            <img
              src={profilePhoto}
              className="about-photo"
              alt=""
              style={{
                width: 400,
                borderRadius: 16,
              }}
            />
            <div
              className="about-logos"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: 10
              }}
            >
            </div>
          </div>
          <h3>I’m here to help you</h3>
          <h3 style={{ marginBottom: 20 }}className='bold'>take your life back.</h3>
        </div>
        <div
          id="welcome"
          className="welcome-text"
          style={{
            marginLeft: 20,
            fontSize: 20,
            maxWidth: 600
          }}>
          <p>
            Welcome and thank you for visiting!
          </p>
          <p>
            This is a place to explore the possibility of change for a <b>better tomorrow</b>. Through therapy, cognitive assessment, training for organizations, and coaching through the Finding Center App, it is my mission to help those I work with <b>recognize and break free</b> of the patterns keeping them stuck.
          </p>
          <p>
            If you are ready to take an active approach to building the life you're dreaming of, this is the place for you!
          </p>
          <p>
            There is no “one size fits all” approach to our time together. I work collaboratively with my clients to create an environment of <b>respect and compassion</b> that can be applied to renew their lives and spark <b>meaningful and lasting change</b>.
          </p>
          <p>
            Clients know me for being <b>down to earth, genuine, and relatable</b>. I appreciate humor and believe that the best care happens through true connection.
          </p>
          <p>
            Finding <b>Meaning, Satisfaction, & Greater Wellbeing</b> Is Possible. I work with eating concerns, body image, sexuality, burnout, changing phases of life and health, improving relationships, managing medical conditions, and living in alignment with ones values.
          </p>
          <p>
            I look forward to partnering together to build <b>your better tomorrow</b>.
          </p>
        </div>
      </div>
    </Section >
  );
};