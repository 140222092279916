import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { AboutMe } from '../sections/AboutMe';
import appIcon from "../imgs/new/logo.png";
import { Hero } from '../sections/Hero';
// import laptopTherapy from '../imgs/laptop.webp';
import findingCenterPhone from '../imgs/new/finding-center-screenshot.png';
// @ts-ignore
import video from '../videos/app-vid.mp4';
// @ts-ignore
import poppyVid from '../videos/poppy-vid.mp4';

import facebook from '../imgs/facebookk.webp';
import insta from '../imgs/instagram.webp';
import youtube from '../imgs/youtube.webp';
import linkd from '../imgs/linkedin.webp';

import { SocialButton } from '../components/SocialButton';

// @ts-ignore
import laptop1 from '../videos/laptop1.mp4';
// @ts-ignore
import laptop2 from '../videos/laptop2-1.mp4';
// @ts-ignore
import capcityVid from '../videos/capacityy.mp4';
// @ts-ignore
import groupVid from '../videos/group.mp4';

import { Section } from '../components/Section';

import { Button } from '../components/Button';
import { CircleDivider } from '../sections/CircleDivider';
import { Contact } from '../Contact';
import { ContactSect } from '../sections/ContactSect';

export const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (location.hash?.includes('about')) {
      document.getElementById('about')?.scrollIntoView()
    }
  }, [location.hash]);

  return (
    <div className="home-page">
      <div style={{ overflowX: "hidden" }}>
        <Hero />
        <CircleDivider
          title="Therapy, Assessment, Training, and Coaching"
          subtitle="For those who want to live better, sacrifice less, and come home to themselves."
        />
        <AboutMe />
        <Section className='field-vid-section' style={{ overflow: "hidden", padding: 0 }}>
          <video
            src={poppyVid}
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
            style={{
              objectFit: "cover",
              minWidth: "100%",
              minHeight: "100%",
            }}
          />
          <div className='vid-overlay'>
            <div className='left-text'>
              <h4>Finding</h4>
              <h2>Meaning Satisfaction & Greater Wellbeing</h2>
            </div>
            <div className='right-text'>
              <h2>Is possible.</h2>
            </div>
          </div>
        </Section>
        <Section id="services" morePadding={true} className="choose-concierge-section">
          <h4 className="subtitle">How can I help you today?</h4>
          <div className='services-grid'>
            <div className="choose-app">
              <div style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}>
                <video style={{
                  width: 250,
                  height: 200,
                  objectFit: "cover",
                  objectPosition: "50% 20%",
                  borderRadius: 4,
                }}
                  autoPlay={true}
                  loop={true}
                  src={laptop2}
                  muted={true}
                  playsInline={true}
                />
              </div>
              <div className='choose-app-text-container'>
                <h4 className='playfair'>Individual & Couples Psychotherapy</h4>
                <p>Convenient remote appointments structured around your schedule and driven by your needs and goals.</p>
                <Button text="Therapy" onPress={() => navigate("/services/therapy")} />
              </div>
            </div>
            <div className="choose-app">
              <div style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}>
                <video style={{
                  width: 250,
                  height: 200,
                  objectFit: "cover",
                  objectPosition: "50% 20%",
                  borderRadius: 4,
                }}
                  autoPlay={true}
                  loop={true}
                  src={capcityVid}
                  muted={true}
                  playsInline={true}
                />
              </div>
              <div className='choose-app-text-container'>
                <h4 className='playfair'>Capacity Evaluations</h4>
                <p>In-person testing of cognitive decision making capacity available in the greater Sacramento area. Compassionate and <b>quick</b> focused assessments.</p>
                <Button text="Assessment" onPress={() => navigate("/services/capacity-testing")} />
              </div>
            </div>
            <div className="choose-app">
              <div style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}>
                <video style={{
                  width: 250,
                  height: 200,
                  objectFit: "cover",
                  objectPosition: "50% 20%",
                  borderRadius: 4,
                }}
                  autoPlay={true}
                  loop={true}
                  src={groupVid}
                  muted={true}
                  playsInline={true}
                />
              </div>
              <div className='choose-app-text-container'>
                <h4 className='playfair'>Workplace Trainings</h4>
                <p>Organizations are built on values, purpose, and meaning. Let's build a work culture that reflects the best of us.</p>
                <Button text="DEI Training" onPress={() => navigate("/services/workplace-training")} />
              </div>
            </div>
            <div className="choose-app">
              <div style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}>
                <img src={findingCenterPhone} style={{
                  height: 200,
                  width: 250,
                  borderRadius: 4,
                  objectFit: "cover",
                  overflow: 'hidden',
                }} alt="" />
                <div style={{
                  width: "100%",
                  position: "absolute",
                  zIndex: 3,
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  <video style={{
                    width: "100%",
                    height: 127
                  }}
                    autoPlay={true}
                    loop={true}
                    src={video}
                    muted={true}
                    playsInline={true}
                  />
                </div>
              </div>
              <div className='choose-app-text-container'>
                <h4 className='playfair'>Finding Center App</h4>
                <p>The first and only Intuitive Eating class app! Psychologist-built to provide targeted resources and one-on-one support.</p>
                <Button text="Finding Center App" onPress={() => navigate("/services/finding-center-app")} />
              </div>
            </div>
          </div>
        </Section>
        <Section morePadding={true} className='next-gen-section'>
          <h3>
            The next generation of <span style={{ color: '#20ABAD' }}><b>holistic wellbeing</b></span> and <b>concierge support</b> is here to help you <b>when and where you need it most</b>.
          </h3>
        </Section>
        <ContactSect
          text={(
            <h4
              style={{
                fontSize: 30,
                fontWeight: 'normal',
                marginBottom: 25,
                textAlign: 'center'
              }}
            >
              Give yourself the gift of more <b>energy</b>, more <b>connection</b>, and the <b>support</b> you've been missing.
            </h4>
          )}
        />
        {/* <Section className='social-connect-section'>
          <h4 className='playfair'>Connect with me on Social Media!</h4>
        </Section> */}
      </div>
    </div>
  );
};