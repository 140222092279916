import React from 'react';
import { CircleDivider } from '../../sections/CircleDivider';
import { Section } from '../../components/Section';
import { Individual } from '../../components/Individual';

import brain from '../../imgs/new/brain-damage.webp';
import ageRelatedDecline from '../../imgs/new/age-related-decline.webp';
import agingIntoAdulthood from '../../imgs/new/aging-into-adulthood.webp';
import { ContactSect } from '../../sections/ContactSect';

// @ts-ignore
import telVid from '../../videos/capacitytelephoneconsult.mp4';
// @ts-ignore
import evalVid from '../../videos/eval.mp4';

// @ts-ignore
import docVid from '../../videos/documentation.mp4';
import { Expected } from '../../components/Expect';

export const CapacityTesting = () => {
  return (
    <div className="capacity-testing-page">
      <CircleDivider
        title="Sacramento Capacity Evaluations"
        subtitle="In-person testing of cognitive decision making capacity available in the greater Sacramento area.  Compassionate and quick focused assessments."
      >
        <p><b>Contact me for details</b></p>
      </CircleDivider>
      <WhatIsSection />
      <WhoNeedsSection />
      <HowDoesItWorks />
      <CapacityFAQs />
      <ContactSect text={(
        <h4 style={{ fontWeight: 'normal' }}>
          Let’s build your better tomorrow <span className='breathing' style={{ fontSize: 60 }}>together</span>...
        </h4>
      )} />
    </div>
  );
};

const WhatIsSection = () => {
  return (
    <Section morePadding={true} className='what-is-evaluation'>
      <h4 style={{ textAlign: 'center' }} className='subtitle'>What is a "mental capacity evaluation?"</h4>
      <p>
        Hearing the news that you or a loved one needs to complete a “cognitive assessment,” “mental capacity evaluation,”
        or “decision making testing” can feel overwhelming. Often, these assessments are required by institutions related to
        important choices and actions such as those impacting finances, healthcare, and independent living. This type of
        evaluation often needs to be done <u>quickly</u> as well as <b>carefully</b>.
      </p>
      <p style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
        I am here to help. We can manage this next stage together.
      </p>
      <p>
        While the legal process can be complicated and difficult to navigate, our assessment process doesn’t need to be.
        During the evaluation I will provide an assessment that answers 4 vital questions:
      </p>
      <div className='what-is-eval-questions-container'>
        <VitalQuestion
          num={1}
          text={<>Can this individual <b>understand</b> information presented to them?</>}
        />
        <VitalQuestion
          num={2}
          text={<>Can they <b>appreciate</b> the relevance and impact of this information on their personal situation?</>}
        />
        <VitalQuestion
          num={3}
          text={<>Are they able to <b>reason</b> using consistent logic the pros and cons of various actions and outcomes?</>}
        />
        <VitalQuestion
          num={4}
          text={<>Is this person capable of expressing a consistent <b>choice</b> made on their own using the above criteria?</>}
        />
      </div>
      <p className='eval-questions-end-statement'>
        At the end of our assessment, I will provide all documentation needed to answer the above questions. Common documentation would be a letter of findings, such as explaining whether one has the capacity to make their own financial decisions, or completion of a Capacity Declaration (GC-335) form.
      </p>
    </Section>
  );
};

const VitalQuestion = (props: {
  num: number,
  text: any;
}) => {
  return (
    <div className='what-is-question'>
      <div className='what-is-question-numeral breathing'>
        {`${props.num}.`}
      </div>
      <p className='what-is-question-text'>
        {props.text}
      </p>
    </div>
  );
};

const Block = (props: any) => {
  return (
    <div className="monial-cell">
      <p>
        {props.children}
      </p>
    </div>
  );
};

const WhoNeedsSection = () => {
  return (
    <Section className='who-needs-assessment-section' morePadding={true}>
      <h4 style={{ textAlign: 'center' }} className='subtitle'>Who needs capacity assessment?</h4>
      <p>
        The purpose of the mental capacity assessment is to determine whether a person aged 18 or over is able to make a particular decision at the time that it needs to be made.
      </p>
      <p>
        A mental capacity assessment may happen because a person’s behavior or circumstances change, there are concerns about a person’s capacity, or they may have been diagnosed with a condition or injury that impairs their ability to think.
      </p>
      <div className="individual-grid personalized-grid" style={{ backgroundColor: "white" }}>
        <Individual
          image={brain}
          title="Brain Injury"
          text="Individuals who have suffered a brain injury often have a mental capacity assessment to determine whether a relative or professional needs to make decisions in their best interest because they can no longer do so themselves. Capacity assessment can inform what support may be needed for decisions such as (but not limited to) healthcare, financial, or independent living choices."
        />
        <Individual
          image={ageRelatedDecline}
          title="Age Related Cognitive Decline"
          text="In the case of someone who has suspected dementia, the triggers for this test would be repeated unwise decisions or actions that appear to be irrational, out of character, and/or could cause serious harm. Mental capacity assessments often determine whether an individual is capable of caring for themselves and their affairs or whether they need a greater level of support."
        />
        <Individual
          image={agingIntoAdulthood}
          title="Aging Into Adulthood"
          text="Capacity evaluation can help provide information about what a young adult born with a brain injury or developmental challenge can do as they enter the adult stage of life. Evaluation can help ensure the level of support needed to thrive into adulthood remains consistent. This can also help provide peace of mind for caregivers as questions of conservatorship documents arise."
        />
      </div>
    </Section>
  );
};

const HowDoesItWorks = () => {
  return (
    <Section className='how-assessments-work-section' morePadding={true}>
      <h4 style={{ textAlign: 'center' }} className='subtitle'>How does a capacity assessment work?</h4>
      <div className="individual-grid personalized-grid">
        <Expected
          video={telVid}
          title="Telephone Consult"
          text={
            <>
              We will have an initial conversation so that I can understand your situation, and ensure that this evaluation will meet your needs. During that conversation we will discuss any additional information needed (such as medication evaluation and lab testing from the patients medical doctor) as well as schedule the testing appointment.

              <p style={{ marginTop: 20 }}>
                <i>
                  30 minute initial telephone consult— free
                </i>
              </p>
              <p>
                <i>
                  ($500 deposit is required to schedule the next step)
                </i>
              </p>
            </>
          }
        />
        <Expected
          video={evalVid}
          title="Assessment Appointment"
          text={
            <>
              On the day of the assessment the individual being assessed as well as trusted caregiver(s) will meet. The basic structure of the day is as follows:
              <ol style={{
                paddingLeft: 35
              }}>
                <li>
                  Interview
                </li>
                <li>
                  One-on-one testing
                </li>
                <li>
                  Feedback conversation
                </li>
              </ol>
              <p>
                Ideally this appointment will be completed in one session. Should additional time be required, we will discuss details before proceeding.
              </p>
              <p style={{ marginTop: 20 }}>
                <i>
                  2-6 hours over 1-2 days — $2,500
                </i>
              </p>
              <p>
                <i>
                  (Fee inclusive of $500 deposit)
                </i>
              </p>
            </>
          }
        />
        <Expected
          video={docVid}
          title="Documentation"
          text={
            <>
              I will provide you <u>timely</u> documentation outlining the findings of the assessment. This could include completing a document provided by the requesting parties (such as the “Capacity Declaration” or GC-335 form), a bespoke letter outlining the findings, or both. Consulting with your legal representative can help you understand what documentation you need.
              <p style={{ marginTop: 20 }}>
                <i>
                  Usually 1-2 days — Included
                </i>
              </p>
            </>
          }
        />
      </div>
    </Section>
  );
};

/*
Capacity to live independently (the capacity to manage or arrange assistance with all personal affairs)
Financial capacity (the capacity to manage financial affairs and/or make financial decisions)
Testamentary capacity (capacity to make a will)
Capacity to make healthcare decisions
Capacity to identify and select a surrogate decision-maker (e.g., POA, POLST, Advanced Directive)
Donative (gift-giving) capacity
Capacity to accept or refuse services offered by Adult Protective Services (APS)
Contractual capacity

*/

export const CapacityFAQs = () => {
  return (
    <Section className='capacity-faqs-section' morePadding={true}>
      <h4 style={{ textAlign: 'center' }} className='subtitle'>Capacity FAQs</h4>
      <div>
        <div>
          <p>1. <b>Where do you offer capacity evaluations?</b></p>
          <Outline
            letter="a"
            text={"I practice out of Sacramento. Within the greater Sacramento region, we can make arrangements to have the evaluation closer to the home of the individual being assessed."}
          />
        </div>
        <div>
          <p>2. <b>What types of capacity do you assess for?</b></p>
          <Outline
            letter="a"
            text={"Capacity to live independently (the capacity to manage or arrange assistance with all personal affairs)"}
          />
          <Outline
            letter="b"
            text={"Financial capacity (the capacity to manage financial affairs and/or make financial decisions)"}
          />
          <Outline
            letter="c"
            text={"Testamentary capacity (capacity to make a will)"}
          />
          <Outline
            letter="d"
            text={"Capacity to make healthcare decisions"}
          />
          <Outline
            letter="e"
            text={"Capacity to identify and select a surrogate decision-maker (e.g., POA, POLST, Advanced Directive)"}
          />
          <Outline
            letter="f"
            text={"Donative (gift-giving) capacity"}
          />
          <Outline
            letter="g"
            text={"Capacity to accept or refuse services offered by Adult Protective Services (APS)"}
          />
          <Outline
            letter="h"
            text={"Contractual capacity"}
          />
        </div>
      </div>
      <div>
        <p>3. <b>Are the result of a capacity evaluation always final?</b></p>
        <Outline
          letter="a"
          text={"Not always. Some conditions may change with time. Typically these conditions are assessed for temporary decision making capacity. Should a change occur, re-evaluation can be completed."}
        />
      </div>
      <div>
        <p>4. <b>How much do capacity evaluations cost?</b></p>
        <Outline
          letter="a"
          text={"The base fee is $2,500 including deposit and all services. If additional costs are incurred, such as (but not limited to) the need to travel closer to the home of the individual being assessed, the need for additional assessment days, or to coordinate with agencies/organizations, additional fees may be added."}
        />
      </div>
      <div>
        <p>5. <b>Why might you need to coordinate with a medical doctor?</b></p>
        <Outline
          letter="a"
          text={"In the case of some conditions, such as dementia, symptoms can be caused or exacerbated by underlying medical conditions. For example, medication interactions or infection. Consultation with a medical doctor can help ensure that an individual’s cognitive symptoms are not the result of a resolvable medical concern."}
        />
      </div>
      <div>
        <p>6. <b>Is this covered by insurance?</b></p>
        <Outline
          letter="a"
          text={"It depends. I would be happy to provide you a Superbill which you may use to request reimbursement. The coverage you receive is entirely dependent upon the plan that you have. I recommend that you contact your insurance company directly to determine your specific coverage."}
        />
      </div>
      <div>
        <p>7. <b>I have more questions!</b></p>
        <Outline
          letter="a"
          text={"I’d be happy to connect with you. Please feel free to send me a message!"}
        />
      </div>
    </Section>
  );
};

const Outline = (props: {
  letter: string;
  text: any;
}) => {
  return (
    <p className='indent'>{props.letter}. {props.text}</p>
  );
}
