import React from "react";
import cn from "classnames";

export const Button = (
  props: {
    onPress?: any;
    text?: string;
    disabled?: boolean;
    className?: string;
    style?: React.CSSProperties;
    secondary?: boolean;
  }
) => {

  const handlePress = () => {
    if (!props.disabled) {
      props.onPress?.();
    }
  };
  return (
    <div
      onClick={handlePress}
      className={cn("button", props?.className, {
        "--secondary": props.secondary,
        "--disabled": props.disabled
      })} 
      style={props.style}
    >
      {props.text}
    </div>
  );
};