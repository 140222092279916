import React from "react";
import cn from "classnames";

export const LinkButton = (
  props: {
    text?: any;
    disabled?: boolean;
    className?: string;
    style?: React.CSSProperties;
    secondary?: boolean;
    to: string;
    newTab?: boolean;
  }
) => {

  let target = "_self";
  if (props.newTab) {
    target = "_blank";
  }

  return (
    <a
      target={target}
      href={props.to}
      className={cn("button", props?.className, {
        "--secondary": props.secondary,
        "--disabled": props.disabled
      })}
      style={{
        minWidth: 300,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        ...props.style,
      }}
    >
      {props.text}
    </a>
  );
};