import playstore from '../imgs/playstore.webp';
import { LinkButton } from './LinkButton';

export const GooglePlayButton = (props: any) => {
  return (
    <LinkButton
    style={{
      height: 55,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      borderRadius: 35
    }}
    className="app-button"
    text={(
      <div style={{
        display: "flex",
        alignItems: "center",
        textAlign: "left"
      }}>
        <div>
          <img src={playstore} alt="" />
        </div>
        <div style={{
          marginLeft: 20
        }}>
          <div className="app-button-text">Finding Center</div>
          <div className="app-button-text" style={{ fontWeight: 700, marginTop: 5 }}>Google Play</div>
        </div>
      </div>
    )}
    to="https://play.google.com/store/apps/details?id=com.p0ayszj1bovol.p0mm5m457app"
    newTab={true}
    secondary={true}
  />
  );
};