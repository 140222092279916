import React from "react";
import { Section } from "../components/Section";

export const Hero = () => {
  return (
    <Section
      className="hero-section"
      morePadding={true}
    >
      <div className='hero-underlay' />
      <div className='hero-under-overlay'/>
      <div className="hero-content-container">
        <div
          className="name-header-container"
        >
          <h2>Stephanie Smith Psy.D.</h2>
          <h4>{`Therapy, Assessment, Training, \n
and Coaching`}</h4>
        </div>
        <div className="right-this-way">
          <h3>
            Your better tomorrow is waiting...
          </h3>
        </div>
        <div className="right-this-way">
          <h4>Right this way.</h4>
        </div>
      </div>
    </Section >
  );
};