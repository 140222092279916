

export const Individual = (props: any) => {
  let secondary;

  if (props.secondaryText) {
    secondary = <p style={{ marginTop: 25 }}>{`${props.secondaryText}`}</p>;
  }
  return (
    <div className="individual-cell">
      <div className="individual-image">
        <img src={props.image} alt="" />
      </div>
      <div className="individual-text">
        <h4 className='playfair'>{props.title}</h4>
        <p>{props.text}</p>
        {secondary}
      </div>
    </div>
  );
};


export const IndividualVid = (props: any) => {
  let secondary;

  if (props.secondaryText) {
    secondary = <p style={{ marginTop: 25 }}>{`${props.secondaryText}`}</p>;
  }
  return (
    <div className="individual-cell">
      <div className="individual-image individual-vid">
        <video
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}
          src={props.video}
        />
      </div>
      <div className="individual-text">
        <h4 className='playfair'>{props.title}</h4>
        <p>{props.text}</p>
        {secondary}
      </div>
    </div>
  );
};