import React from 'react';
import axios from 'axios';
import { Button } from './components/Button';

function Loading() {
  return (
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}


export function Contact() {
  const name = React.useRef<any>();
  const email = React.useRef<any>();
  const message = React.useRef<any>();
  const [submitError, setSubmitError] = React.useState(null);
  const [nameEntered, setNameEntered] = React.useState(false);
  const [nameValid, setNameValid] = React.useState(true);
  const [emailEntered, setEmailEntered] = React.useState(false);
  const [emailValid, setEmailValid] = React.useState(true);
  const [messageEntered, setMessageEntered] = React.useState(false);
  const [messageValid, setMessageValid] = React.useState(true);
  const [waitingResponse, setWaitingResponse] = React.useState(false);
  const [responseError, setResponseError] = React.useState<any>();
  const [responseSuccess, setResponseSuccess] = React.useState<any>(false);

  const [emailError, setEmailError] = React.useState(null);
  const [messageError, setMessageError] = React.useState(null);
  const [nameError, setNameError] = React.useState(null);

  const onNameChange = (e: any) => {
    if (e.currentTarget.validity.valid && !nameValid) {
      setNameValid(true);
    } else if (!e.currentTarget.validity.valid && nameValid) {
      setNameValid(false);
    }
    if (responseError) {
      setResponseError(false);
    }
    if (!nameEntered) {
      setNameEntered(true);
    }
  };

  const onEmailChange = (e: any) => {
    if (e.currentTarget.validity.valid && !emailValid) {
      setEmailValid(true);
    } else if (!e.currentTarget.validity.valid && emailValid) {
      setEmailValid(false);
    }
    if (responseError) {
      setResponseError(false);
    }

    if (!emailEntered) {
      setEmailEntered(true);
    }
  };

  const onMessageChange = (e: any) => {
    if (e.currentTarget.validity.valid && !messageValid) {
      setMessageValid(true);
    } else if (!e.currentTarget.validity.valid && messageValid) {
      setMessageValid(false);
    }
    if (responseError) {
      setResponseError(false);
    }

    if (!messageEntered) {
      setMessageEntered(true);
    }
  };

  const enabled = nameValid
    && emailValid
    && messageValid && nameEntered && emailEntered && messageEntered;

  const onSendSecureMessageSuccess = () => {
    setWaitingResponse(false);
    setResponseSuccess({});
  };

  const onSendSecureMessageError = (error: any) => {
    if (error.submit) {
      setSubmitError(error.submit.message);
    }

    if (error.name) {
      setNameError(error.name.message);
    }

    if (error.email) {
      setEmailError(error.email.message);
    }

    if (error.message) {
      setMessageError(error.message.message);
    }

    setWaitingResponse(false);
  };

  const onSendClick = () => {
    if (enabled) {
      setSubmitError(null);
      setNameError(null);
      setEmailError(null);
      setMessageError(null);

      setWaitingResponse(true);
      sendSecureMessage(
        {
          name: name.current?.value,
          email: email.current?.value,
          message: message.current?.value,
        },
        onSendSecureMessageSuccess,
        onSendSecureMessageError,
      );
    }
  };

  let nameInvalidMessage;
  if (!nameValid) {
    nameInvalidMessage = 'Please provide your name.';
  }

  let emailInvalidMessage;
  if (!emailValid) {
    emailInvalidMessage = 'Please provide your email address.';
  }

  let messageInvalidMessage;
  if (!messageValid) {
    messageInvalidMessage = 'Please provide a message that is at least 50 characters long.';
  }

  let nameClasses;
  if ((name.current && name.current.value.trim().length === 0) || !name.current) {
    nameClasses = 'empty';
  }
  let emailClasses;

  if ((email.current && email.current.value.trim().length === 0) || !email.current) {
    emailClasses = 'empty';
  }

  let messageClasses;

  if ((message.current && message.current.value.trim().length === 0) || !message.current) {
    messageClasses = 'empty';
  }

  let overlay;
  let submitErrorMessage;
  if (submitError) {
    submitErrorMessage = submitError;
  }
  if (nameError) {
    nameInvalidMessage = nameError;
  }
  if (emailError) {
    emailInvalidMessage = emailError;
  }
  if (messageError) {
    messageInvalidMessage = messageError;
  }

  if (responseSuccess) {
    overlay = (
      <div style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 10,
        backgroundColor: '#ada5a0',
        borderRadius: 4,
      }}
      >
        <h3 style={{ color: 'var(--cta)', textAlign: 'center', paddingLeft: 10, paddingRight: 10 }}>
          {'Your secure message has been sent!'}
        </h3>
        <h4 style={{ textAlign: 'center', paddingLeft: 10, paddingRight: 10 }}>
          {'Thank you.'}
        </h4>
      </div>
    );
  } else if (waitingResponse) {
    overlay = (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderRadius: 4,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 10
      }}
      >
        <h4 style={{ textAlign: 'center', color: '#FFFFFF' }}>Sending secure message...</h4>
        <Loading />
      </div>
    );
  }

  const body = (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center'
    }}
    >
      <div style={{ position: 'relative', width: '100%', paddingTop: 20 }}>
        <div className="group">
          <input type="text" required ref={name} minLength={1} onChange={onNameChange} className={nameClasses} />
          <span className="highlight" />
          <span className="bar" />
          <label>Name</label>
          <span style={{ fontSize: 12, color: '#D1011B' }}>{nameInvalidMessage}</span>
        </div>
        <div className="group">
          <input type="email" required ref={email} minLength={3} onChange={onEmailChange} className={emailClasses} />
          <span className="highlight" />
          <span className="bar" />
          <label>Email</label>
          <span style={{ fontSize: 12, color: '#D1011B' }}>{emailInvalidMessage}</span>
        </div>
        <div className="group">
          <textarea
            required
            ref={message}
            minLength={50}
            onChange={onMessageChange}
            rows={5}
            className={messageClasses}
          />
          <span className="highlight" />
          <span className="bar" />
          <label>Message</label>
          <span style={{ fontSize: 12, color: '#D1011B' }}>{messageInvalidMessage}</span>
        </div>
      </div>
      <div className="readMore">
        <Button disabled={!enabled} onPress={onSendClick} style={{ width: 300 }} text="Send"/>
      </div>
      <span style={{ fontSize: 12, color: '#D1011B' }}>{submitErrorMessage}</span>
      <div style={{ marginTop: 15, fontSize: 12, textAlign: 'center' }}>
        {`Your message is private and secure.`}
      </div>
    </div>
  );

  return (
    <div className="contact-form">
      <div className="content" style={{width: '100%' }} id="top">
        {body}
        {overlay}
      </div>
    </div>
  );
}


const Axios = axios.create({
  validateStatus: (status) => status < 500
});

export default function sendSecureMessage(
  form: any,
  onSuccess: any,
  onError: any
) {
   Axios({
    method: 'POST',
    baseURL: `https://www.stephaniesmithpsyd.com/mailer`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      ...form
    }
  }).then((res) => {
    if (statusOk(res.status)) {
      if (res.data.errors) {
        onError(res.data.errors);
      } else {
        onSuccess(res.data);
      }
    } else {
      onError(res.data);
    }
  }).catch((e) => {
    console.error(e);
  });
}

export const statusOk = (status: number) => {
  return status >= 200 && status < 300;
}
