import { NavLink } from "react-router-dom";
import { CloseButton } from "./CloseButton";
import cn from 'classnames';

type NavItem = {
  text: string;
  to?: string;
  inline?: boolean;
  items?: NavItem[];
}

export const NavMenu = (props: {
  items: NavItem[]
  open?: boolean;
  onCloseButtonPress(): void;
  onItemPressed(text: string): void;
}) => {
  let classes = "nav-menu";
  if (props.open) {
    classes = classes.concat(" --open");
  }

  const menuItems = props.items.filter(item => item.text !== "Services")
    .map(item => <NavMenuItem key={item.text} onPress={props.onItemPressed} size="large" {...item} />)
  
  const servicesItems = props.items.find(item => item.text === "Services");
  const servicesMenuItems = servicesItems!.items!.map(item => <NavMenuItem key={item.text} onPress={props.onItemPressed} size="large" {...item} />)

  return (
    <div className={classes}>
      <div className="menu-container">
        {/* <div className="menu-photo">
          <div className="menu-photo-backdrop">
            <h2>Let's create your better tomorrow together...</h2>
            <h2>Right this way.</h2>
          </div>
        </div> */}
        <div className="menu">
          {menuItems}
          <div style={{
            width: '100%',
            borderBottom: '2px solid white',
            textAlign: 'right',
            fontSize: 32,
            color: 'white',
            marginBottom: 10,
            fontFamily: 'Playfair Display, sans-serif'
          }}>
            Services
          </div>
          {servicesMenuItems}
        </div>
        <CloseButton onPress={props.onCloseButtonPress} />
      </div>
    </div>
  );
};



type MenuItemProps = {
  expanded?: boolean;
  submenu?: boolean;
  onPress: ((text: string) => void);
  size: 'large' | 'small';
} & NavItem;

const NavMenuItem = (props: MenuItemProps) => {
  const {
    text,
    submenu,
    onPress,
    size,
    expanded,
    to,
  } = props;

  const classes = cn(`${size}-menu-item`, 'menu-item', 'mobile-menu-item', {
    '--main-menu': !submenu,
    '--sub-menu': submenu,
  });

  const handlePress = () => {
    onPress(text);
  };

  let content: any = <NavLink to={to ?? "/"} className={`nav-link --${text.toLowerCase()}`}>{text}</NavLink>;

  const textClasses = cn("menu-item-text", {
    "--expanded": expanded,
  })

  return (
    <div
      onClick={handlePress}
      className={classes}
    >
      <div 
        className={textClasses}
      >
        {content}
      </div>
    </div>
  );
};