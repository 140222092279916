export const Saying = (props: any) => {
  return (
    <div className="what-people-cell">
      <div className="what-people-image">
        <img src={props.image} alt=""/>
      </div>
      <div className="what-people-text">
        <h4 className='playfair'>{props.name}</h4>
        <p>{`"${props.text}"`}</p>
      </div>
    </div>
  );
};