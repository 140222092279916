import cn from "classnames";

export const Section = (props: any) => {
  const classes = cn("section", props.className, {
    "--morePadding": props.morePadding
  });

  const {
    className,
    morePadding,
    children,
    ...remaining
  } = props;
  return (
    <div {...remaining} className={classes}>
      {props.children}
    </div>
  );
};