import React from 'react';
import { CircleDivider } from '../../sections/CircleDivider';
import { Section } from '../../components/Section';
import { Saying } from '../../components/Saying';

import maggie from '../../imgs/maggie.webp';
import tim from '../../imgs/tim.webp';
import linda from '../../imgs/linda.webp';
import piper from '../../imgs/piper.webp';
import heather from '../../imgs/heather.webp';
import rachelali from '../../imgs/rachelali.webp';

// @ts-ignore
import initialVideo from '../../videos/initialappointment.mp4';
// @ts-ignore
import returnVideo from '../../videos/returnappointments.mp4';
// @ts-ignore
import telephoneVideo from '../../videos/telephoneconsult.mp4';

import joyOfMed from '../../imgs/new/joy-of-med.webp';
import { Individual } from '../../components/Individual';
import couplesSupport from "../../imgs/couplessupport.webp";

import eatingConcerns from '../../imgs/eating-concerns.webp';
import lifeTransitions from '../../imgs/life-transitions.webp';
import relationships from '../../imgs/relationships.webp';
import bodyImage from '../../imgs/bodyimage.webp';
import healthDifficulties from '../../imgs/healthdifficulties.webp';
import stressManagement from '../../imgs/stress.webp';
import { Expected } from '../../components/Expect';

import { MeetTestimonials } from '../../sections/MeetTestimonials';
import { ContactSect } from '../../sections/ContactSect';

export const Therapy = () => {
  return (
    <div className="therapy-page">
      <CircleDivider
        title={"Personalized Psychotherapy"}
        subtitle={`Convenient remote individual and couples appointments
         structured around your schedule and driven by your needs and goals.`}
      >
        <p><b>Rates and services vary!</b></p>
      </CircleDivider>
      <Section className="what-people-are-saying-section" style={{ backgroundColor: '#FCF8F5', minHeight: 450 }} morePadding={true}>
        <h4 className="subtitle">What people are saying...</h4>
        <div className="what-people-grid">
          <Saying
            name="Maggie C."
            text="When we talk I feel like I'm ok to be me. Like I'm not some crazy person who is out of control. 
            I'm learning to see myself through your eyes. I love carrying my therapist with me in my pocket!"
            image={maggie}
          />
          <Saying
            name="Tim R."
            text="It's so hard to find the right therapist. Being able to talk and come up with real solutions has been a 
            game changer. Bringing in medication recommendations has also been hugely helpful for getting on the right combo."
            image={tim}
          />
          <Saying
            name={`Rachel & Ali K.`}
            text="I'm so glad that we did this. Trying to be everything to everyone was killing me, and our relationship. 
            I can't believe that I can just do me and that's actually better than ok."
            image={rachelali}
          />
          <Saying
            name="Linda S."
            text="It's sort of amazing that you work with the food stuff, and the health stuff, and the relationship stuff. 
            It took me 6 months to find a therapist just for one of those things!"
            image={linda}
          />
          <Saying
            name="Heather A."
            text="Words can't describe the changes I've experienced. I feel brave, more at peace, heard, understood, and allowed 
            breathing room. I thank you for that"
            image={heather}
          />
          <Saying
            name="Piper L."
            text="I haven’t binged in months. I thought I couldn’t be trusted so I made rules for myself that I always broke. 
            Now I don't use rules at all, and I just stop when I'm done. You have no idea how great that feels."
            image={piper}
          />
        </div>
      </Section>
      <Section morePadding={true} style={{
        backgroundColor: "white"
      }}>
        <h4
          className="subtitle"
        >
          Individual Therapy
        </h4>
        <p style={{
          marginBottom: 25
        }}>
          If you're in the state of California, one-on-one psychotherapy may be an option we could explore. Below is some
          general information about the types of conditions I work with the most. Please feel free to reach out to me if
          you would like to learn more about these services.
        </p>
        <div className="individual-grid personalized-grid" style={{ backgroundColor: "white" }}>
          <Individual
            image={eatingConcerns}
            title="Eating Concerns"
            text="I practice an Intuitive Eating approach that honors the complex role eating can play as a mechanism of coping, 
            as well as the desire that may exist to change. Holistic nourishment may look different for everyone. Our approach 
            is tailored to you."
          />
          <Individual
            image={lifeTransitions}
            title="Life Transitions"
            text="Changes can impact us on every level of our being. Seeking therapy can be both anchor and guide light. 
            I support you to process the loss of how things have been. When you are ready, we'll shift our attention to 
            nurture growth."
          />
          <Individual
            image={relationships}
            title="Relationships"
            text="All types of relationships can be sources of significant pleasure and significant pain. I will help you 
            work on communication clarity and intimacy/vulnerability challenges that may be in the way of your relationship 
            satisfaction."
          />
          <Individual
            image={bodyImage}
            title="Body Image"
            text="Even the most strong, confident people can get stuck in cycles of body hate and shame. We can work together 
            to move the needle to neutrality, acceptance, respect, and possibly even body love."
          />
          <Individual
            image={healthDifficulties}
            title="Health Difficulties"
            text="I am a trained health psychologist and work regularly with both chronic and acute medical conditions. 
            I will work with you to accept those realities that are unchanging, as well as recognize opportunities for 
            positive growth."
          />
          <Individual
            image={stressManagement}
            title="Stress Management"
            text="Going through overwhelm can feel like being trapped in parts of your own life. Therapy can help you find 
            the personal liberation needed to engage with the rest of your life and the people in it from the best 
            version of yourself."
          />
        </div>
        <div
          style={{
            paddingTop: 25,
            backgroundColor: "white"
          }}
        >
          <p style={{
            textAlign: "center",
            fontStyle: "italic",
          }}>
            *Note: As a trained health psychologist, I am able to discuss medications, work with you on a monitoring  plan, and  make recommendations. I do not prescribe medications, though I am happy to collaborate with your doctor.
          </p>
        </div>
      </Section>
      <Section morePadding={true}>
        <h4 className='subtitle'>Couples Therapy</h4>
        <p>
          If you and your partner are in the state of California, couples psychotherapy is an option we can explore. Below is some general information about the couple's support I offer. Please reach out to learn more.
        </p>
        <div
          style={{
            marginTop: 25,
            display: "flex",
            paddingTop: 0,
          }}
          className="individual-horiz"
        >
          <div className="individual-image" style={{
            flex: 1,
            marginBottom: 0,
            marginRight: 25,
          }}>
            <img style={{ marginTop: 'auto', marginBottom: 'auto', }} src={couplesSupport} alt="" />
          </div>
          <div className="individual-text no-max-width">
            <h4 className='playfair'>Couples Support</h4>
            <p>
              There are many elements that can come between a couple and their best intentions for their relationship.
              The reality is that sharing your life with any other person can be a challenge. Old wounds and misunderstandings
              that weren't fully healed at the time can reopen again and again. There is support, and a better future can be possible.
              We will work together to shore up the foundations of your relationship, and get back to what you're good at. From a position
              of stronger base, we can lean into healing with one another. While it is true that not every relationship will last, we
              will work towards the healthiest version of your partnership.
            </p>
          </div>
        </div>
        <div
          style={{
            paddingTop: 20
          }}
        >
          <p style={{
            textAlign: "center",
          }}>
            <i>*It is not recommended to see the same therapist for both individual and couples support.
              If we are working together for one, I can provide referrals for the other if needed.</i>
          </p>
        </div>
      </Section>
      <Section style={{ backgroundColor: '#FFFFFF' }} morePadding={true}>
        <h4 className='subtitle'>Medical Providers</h4>
        <p>
          If you are a physician, medical student, or health officer that practices or attends medical school
          in Sacramento, El Dorado, Yolo, or Placer Counties, you are eligible through the Sierra Sacramento Valley
          Medical Society (SSVMS) for 6 free-of-charge wellness sessions. These sessions can focus on challenges specific to the work,
          such as burnout, but can also be entirely unrelated. Please feel free to reach out to me if you would like to learn more!
        </p>
        <div
          style={{
            marginTop: 25,
            display: "flex",
            paddingTop: 0,
          }}
          className="individual-horiz"
        >
          <div className="individual-image" style={{
            flex: 1,
            marginBottom: 0,
            marginRight: 25,
          }}>
            <img style={{ marginTop: 'auto', marginBottom: 'auto', }} src={joyOfMed} alt="" />
          </div>
          <div className="individual-text no-max-width">
            <h4 className='playfair'>Joy of Medicine</h4>
            <p>
              From your first medical school application to living the realities of life on the job,
              the stressors of medical practice are many. Care for the carers is essential. You deserve a
              place where your needs are the priority. Here, we cultivate an environment free from demands
              except those of your own wellbeing. Sierra Sacramento Valley Medical Society (SSVMS) offers the
              Joy of Medicine (JoM) program to create a community approach to supporting the wellbeing of its
              members. Whether you are struggling with work-related concerns, or stressors outside of work, you
              can access up to 6 wellness sessions free-of-charge and addressing any topic.
            </p>
          </div>
        </div>
        <div
          style={{
            paddingTop: 20
          }}
        >
          <p style={{
            textAlign: "center",
          }}>
            <i>*If continued support would be beneficial after the completion of the JoM sessions, you will be welcome
              to continue care with me! There is no set frequency. We will customize our care plan to your needs.</i>
          </p>
        </div>
      </Section>
      <MeetTestimonials />
      <Section className='better-way-divider' morePadding={true}>
        <div className='better-way-divider-text'>
          <h3>
            There is a better way.
          </h3>
          <h4>
            If you’re struggling with relationships, your body, burnout, or
            the stressors of life, this is your opportunity to make peace.
          </h4>
        </div>
      </Section>
      <Section id="what-to-expect" morePadding={true} className="expect-section">
        <h4
          className="subtitle"
        >What to expect from therapy</h4>
        <div className="individual-grid">
          <Expected
            video={telephoneVideo}
            title="Telephone Consult"
            text={`As our first contact I offer a complementary 15-30 minute phone consultation. 
            This is an opportunity for us to connect about the issues that bring you into care, 
            and to determine whether working together is the best fit for your needs. I can answer any questions 
            that you may have and set up your first appointment.`}
            secondaryText="15-30 minute initial telephone consult— free"
          />
          <Expected
            video={initialVideo}
            title="Initial Appointment"
            text={`
            An initial individual therapy appointment is a 60-minute appointment. During this appointment we will 
            review some of your history, what brings you into care, and your goals for our work together. 
            I will answer any questions that you have. We will create some initial goals and to set up our 
            mutual expectations for treatment.
    `}
            secondaryText="60 minute initial individual appointment— $200"
          />
          <Expected
            video={returnVideo}
            title="Return Appointments"
            text={`
            Return appointments are full 60-minute appointments. During return appointments we will work together on your goals, 
            discuss what you’ve worked on between sessions, and I will support you through your journey. We will regularly 
            check in about the progress of care and make adjustments as needed to ensure that we are working effectively together.
    `}
            secondaryText="50-60 minute return individual appointment— $200"
          />
        </div>
      </Section>
      <Section morePadding={true} className="insurance-section">
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
              width: "40%",
              maxWidth: 500,
              minWidth: 300
            }}
          >
            <h4 className='subtitle'>Insurance</h4>
            <div

              style={{
                backgroundColor: "#E9E8E6",
                padding: 15,
                flex: 1,
                fontSize: 15
              }}
            >
              <p>
                I am an "out of network provider" for insurance networks but will provide you a Superbill which you may use to request reimbursement. 
                The coverage you receive is entirely dependent upon the plan that you have. I recommend that you contact your insurance company 
                directly to determine your specific coverage. Some questions that may be helpful in this conversation include:
                <ol
                  style={{
                    marginTop: 15,
                    marginLeft: 20
                  }}
                >
                  <li>
                    Do I have mental health insurance benefits that cover psychotherapy?
                  </li>
                  <li>
                    Does my policy cover an out of network Licensed Psychologist? If so, what percentage is 
                    covered or what is the coverage amount per therapy session?
                  </li>
                  <li>
                    What is my deductible and has it been met?
                  </li>
                  <li>
                    Are my medical and mental health/behavioral health deductibles separate?
                  </li>
                  <li>
                    How many sessions per year does my health insurance cover?
                  </li>
                  <li>
                    Does the diagnosis matter? Are some diagnoses covered and others not?
                  </li>
                  <li>
                    What paperwork or forms do I need to submit for sessions to be covered?
                  </li>
                  <li>
                    Is approval required from my primary care physician?
                  </li>
                </ol>
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
              width: "40%",
              maxWidth: 500,
              minWidth: 300,

            }}
          >
            <h4 className='subtitle'>Good Faith Estimate</h4>
            <div
              style={{
                backgroundColor: "#E9E8E6",
                padding: 15,
                flex: 1,
                fontSize: 15
              }}
            >
              <p
              >
                You have the right to receive a “Good Faith Estimate” explaining how much your medical and mental health care will cost. 
                Under the law, health care providers need to give clients who don’t have insurance, or who are not using insurance, 
                an estimate of the expected charges for medical services.
              </p>
              <p
                style={{
                  marginTop: 15
                }}
              >
                You have the right to receive a Good Faith Estimate for the total expected cost of any non-emergency healthcare services, 
                including psychotherapy services. You can ask your health care provider, and any other provider you choose, for a Good Faith 
                Estimate before you schedule a service. If you receive a bill that is at least $400 more than your Good Faith Estimate, 
                you can dispute the bill. You will receive a copy of your Good Faith Estimate.
              </p>
              <p
                style={{
                  marginTop: 15
                }}
              >
                For questions or more information about your right to a Good Faith Estimate, visit www.cms.gov/nosurprises.
              </p>
            </div>
          </div>
        </div>
      </Section>
      <ContactSect 
        text={(
          <h4 style={{ fontWeight: 'normal' }}>
          Let’s build your better tomorrow <span className='breathing' style={{ fontSize: 60 }}>together</span>...
        </h4>
        )}
      />
    </div>
  );
};