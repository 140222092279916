import { GetTheAppBar } from '../../components/GetTheAppBar';
import { CircleDivider } from '../../sections/CircleDivider';
import { Section } from '../../components/Section';
import { ContactSect } from '../../sections/ContactSect';
import { LinkButton } from '../../components/LinkButton';

export const FindingCenterApp = () => {
  return (
    <div className="finding-center-app-page">
      <GetTheAppBar />
      <CircleDivider
        title='Finding Center App'
        subtitle='The first and only Intuitive Eating class app! Psychologist-built to provide targeted resources and one-on-one support.'
      >
        <p><b>Rates and services vary!</b></p>
      </CircleDivider>
      <Section className='dreamed-divider-section' style={{ backgroundColor: '#FFFFFF' }} morePadding={true}>
        <div style={{ textAlign: 'center', maxWidth: 550, }}>
          <h4 style={{ fontWeight: 'normal', marginBottom: 5 }} className='subtitle gothic'>
            If you've dreamed of feeling <span style={{ color: 'var(--cta)', fontWeight: 'bold' }}>free and easy</span> around food and in your body...
          </h4>
          <h4 style={{ fontWeight: 'normal', fontSize: 45, color: '#20ABAD', marginBottom: 0 }} className='breathing'>
            This is the place for you.
          </h4>
        </div>
      </Section>
      <Section className='better-way-divider' style={{ minHeight: 225 }}>
        <div style={{ maxWidth: 800 }}>
          <h4>
            A coach-guided app for those who are ready to build a
            healthier relationship with their bodies and with food.
          </h4>
          <LinkButton
            style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 20, maxWidth: 200, borderRadius: 50 }}
            text="Website for the Finding Center App"
            to="https://www.findingcenterapp.com"
            newTab={true}
          />
        </div>
      </Section>
      <Section style={{ backgroundColor: 'var(--cta)', textAlign: 'center', minHeight: 150, justifyContent: 'center', display: 'flex', flexDirection: 'column' }} morePadding={true}>
        <h4 className='subtitle gothic' style={{ marginBottom: 0, fontWeight: 'normal' }}>
          Intuitive Eating allows you to find ease in your body, develop safety with food, and live the life you've longed for.
        </h4>
        <h4 className='subtitle' style={{ color: '#FFFFFF', marginBottom: 0 }}>
          Finding Center with Dr. Smith teaches you how.
        </h4>
      </Section>
      <Section className='finding-center-summary' morePadding={true}>
        <p>
          In the Finding Center <b>Intuitive Eating & Body Confidence</b> program, I walk you through a coach-guided step-by-step
          intensive program to help make peace with food and build body confidence. This course builds upon principles of
          Health at Every Size (HAES), Intuitive Eating, and Body Image work. These are the essential building blocks of a
          holistic and inclusive approach to health and well-being. This program <b>prioritizes individual health and wellness
            over arbitrary standards of weight or appearance</b>, promoting a more sustainable and compassionate relationship with
          your body and food.
        </p>
        <p style={{ color: '#20ABAD', textAlign: 'center', marginTop: 20, marginBottom: 20, fontWeight: 'bold' }} className='playfair'>
          For more information about the app, click the link above to check out the website!
        </p>
        <p style={{ marginBottom: 20 }}>
          For more information about the HAES/Intuitive Eating approach in general, see the key components below:
        </p>
        <div>
          1. Promotes Inclusivity and Body Positivity:
          <p className='indent'>
            &#x25E6; HAES and Intuitive Eating focus on recognizing and respecting the diversity of body shapes and sizes.
            This inclusivity promotes positive body image and self-esteem, fostering a more accepting and tolerant society.
          </p>
        </div>
        <div>
          2. Shifts Focus from Weight to Health:
          <p className='indent'>
            &#x25E6; Traditional approaches often prioritize weight loss, which may not always align with an individual's natural, healthy weight range. HAES and Intuitive Eating shift the focus towards overall well-being, acknowledging that health can be achieved at various sizes.
          </p>
        </div>
        <div>
          3. Reduces Stigma and Discrimination:
          <p className='indent'>
            &#x25E6; Conventional weight-centric approaches can perpetuate weight-based stigma and discrimination. HAES and Intuitive Eating challenge these harmful stereotypes, creating a more supportive environment for individuals of all sizes.
          </p>
        </div>
        <div>
          4. Enhances Mental Health:
          <p className='indent'>
            &#x25E6; Traditional dieting and weight-loss programs can lead to negative psychological outcomes, including disordered eating patterns and low self-esteem. HAES and Intuitive Eating emphasize self-compassion and body autonomy, promoting improved mental health and well-being.
          </p>
        </div>
        <div>
          5. Fosters a Healthy Relationship with Food:
          <p className='indent'>
            &#x25E6; Intuitive Eating encourages a mindful and attuned approach to eating, helping individuals reconnect with their body's natural hunger and fullness cues. This can reduce disordered eating behaviors and promote a more balanced and enjoyable relationship with food.
          </p>
        </div>
        <div>
          6. Addresses Root Causes of Health Issues:
          <p className='indent'>
            &#x25E6; HAES recognizes that health disparities are often linked to socio-economic factors, access to resources, and systemic inequalities. By addressing these underlying issues, it aims to improve overall health outcomes for marginalized communities.
          </p>
        </div>
        <div>
          7. Focuses on Sustainable, Long-Term Health:
          <p className='indent'>
            &#x25E6; HAES and Intuitive Eating promote sustainable health practices that can be maintained over a lifetime. This contrasts with quick-fix diets that often lead to yo-yo dieting and long-term health complications.
          </p>
        </div>
        <div>
          8. Empowers Individuals to Trust Their Bodies:
          <p className='indent'>
            &#x25E6; Intuitive Eating encourages individuals to trust their body's signals, helping them make informed and intuitive choices about food and physical activity. This empowerment fosters a sense of agency and autonomy over one's own health.
          </p>
        </div>
        <div>
          9. Encourages Health Professionals to Adopt an Inclusive Approach:
          <p className='indent'>
            &#x25E6; By providing training in HAES and Intuitive Eating, health professionals become equipped to offer more inclusive and effective care. This can lead to improved patient satisfaction, trust, and overall health outcomes.
          </p>
        </div>
        <div>
          10. Aligns with Evidence-Based Practices:
          <p className='indent'>
            &#x25E6; Research supports the effectiveness of HAES and Intuitive Eating in improving both physical and mental health outcomes. This evidence-based approach provides a solid foundation for promoting overall well-being.
          </p>
        </div>
        <div style={{ marginTop: 20 }}>
          <p><i>
            In summary, Health at Every Size and Intuitive Eating work is crucial for promoting a more inclusive, sustainable, and compassionate approach to health and well-being. By shifting the focus from weight to holistic wellness, we can help you feel empowered to build healthier relationships with your body and food, leading to improved long-term health outcomes.
          </i>
          </p>
        </div>
      </Section>
      <Section className='finding-center-app-last' morePadding={true}>
        <div style={{ maxWidth: 800 }}>
          <h4 className='subtitle gothic' style={{ fontWeight: 'normal' }}>
            If you've tried all the diets and “Lifestyle Changes” and still feel like you're in a constant battle against yourself...
            This is the place for you.
          </h4>
          <LinkButton
            style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 20, maxWidth: 200, borderRadius: 50 }}
            text="Website for the Finding Center App"
            to="https://www.findingcenterapp.com"
            newTab={true}
          />
        </div>
      </Section>
      <ContactSect
        text={(
          <h4 style={{ fontWeight: 'normal' }}>
            Let’s build your better tomorrow <span className='breathing' style={{ fontSize: 60 }}>together</span>...
          </h4>
        )}
      />
    </div>
  );
};