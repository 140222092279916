import React from 'react';
import { CircleDivider } from '../../sections/CircleDivider';
import { Section } from '../../components/Section';

import diversityImg from '../../imgs/new/diverset-training.webp';
import stressImg from '../../imgs/new/stress-management.webp';
import watermelonLady from '../../imgs/new/watermelon-lady.webp';
import { ContactSect } from '../../sections/ContactSect';

export const WorkplaceTraining = () => {
  return (
    <div className="workplace-trainings-page">
      <CircleDivider
        title={"Workplace Trainings"}
        subtitle={`Organizations are built on values, purpose, and meaning.
        Let's build a work culture that reflects the best of who and what we are.`}
      >
        <p><b>Rates and services vary!</b></p>
      </CircleDivider>
      <Section style={{ backgroundColor: '#FCF8F5' }} morePadding={true} className='workplace-trainings-section'>
        <h4 style={{ textAlign: 'center' }} className='subtitle'>Diversity, Equity, and Inclusion (DEI) - Size Bias Training</h4>
        <div
          className='workplace-trainings-section-content'
          style={{
            display: 'flex',
            width: '100%',
          }}>
          <img style={{ maxWidth: 400, maxHeight: 400, marginTop: 'auto', marginBottom: 'auto' }} src={diversityImg} alt="" />
          <div style={{
            marginLeft: 25,
          }}>
            <p>
              Living in our culture makes it impossible to avoid the development of many implicit biases none of us would choose willingly.
              Because the harm of size/weight bias has only recently been understood and brought into the light, many have not been aware of
              their own biased thoughts and opinions.  While we cannot control the onset of our biases, we <b>can</b> choose to become aware of them,
              and work to mitigate the harm they cause.
            </p>
            <p style={{ marginTop: 20 }}>
              Unchecked, bias and stigma are associated with staggering inter-organizational costs, as well as
              difficulties within and outside of work. When it comes to body biases, the damage can include physical harm as well as increased burnout.
            </p>
            <p style={{ marginTop: 20 }}>
              Diversity, Equity, and Inclusion training helps your team work against the tide of these risks. Building a more just workplace improves safety
              and helps reconnect to our deeper values; the "why" behind the work.
            </p>
            <p style={{ marginTop: 20, textAlign: 'center' }}>
              <i>
                <b>*I specialize with body bias trainings focusing on Size/Weight Bias.</b>
              </i>
            </p>
            <p style={{ textAlign: 'center' }}>
              <i>
                If you have questions about these trainings, please let me know! Each training is tailored for the unique goals of the organization or group.
              </i>
            </p>
          </div>
        </div>
      </Section>
      <Section style={{ backgroundColor: '#FFFFFF' }} morePadding={true} className='workplace-trainings-section'>
        <h4 style={{ textAlign: 'center' }} className='subtitle'>Stress Management & Burnout Prevention</h4>
        <div
          className='workplace-trainings-section-content reverse-flex-column'
          style={{
            display: 'flex',
            width: '100%',
          }}>
          <div style={{
            marginRight: 25,
          }}>
            <p>
              Burnout is characterized in the ICD-11 by the following three dimensions:
              <ul style={{ marginLeft: 25 }}>
                <li>feelings of energy depletion or exhaustion;</li>
                <li>increased mental distance from one’s job, or feelings of negativism or cynicism related to one's job; and</li>
                <li>reduced professional efficacy.</li>
              </ul>
            </p>
            <p style={{ marginTop: 20 }}>
              Almost everyone can relate to these symptoms, either in their own experience, or in observing those around them. Burnout is incredibly common, and has huge impacts on the wellbeing of organizations and the people who work within them.
            </p>
            <p style={{ marginTop: 20 }}>
              The good news is we can absolutely do something about this! Even better? <b>You don't have to go to a single yoga
                class to do it.</b> (Unless, of course, you want to!) There are ways to manage burnout and stress that don't feel like
              taking on yet another task, or represent another benchmark to struggle towards. Meaningful relief within the schedule of real life <b>is</b> possible.
            </p>
            <p style={{ marginTop: 20, textAlign: 'center' }}>
              <i>*Trainings are each modified for the unique goals of the organization or group. Sharing with me about your
                organization and goals of training will help tailor our time to meet your needs.</i>
            </p>
          </div>
          <img style={{ maxWidth: 400, maxHeight: 400, marginTop: 'auto', marginBottom: 'auto' }} src={stressImg} alt="" />
        </div>
      </Section>

      <Section style={{ backgroundColor: '#FCF8F5' }} morePadding={true} className='workplace-trainings-section'>
        <h4 style={{ textAlign: 'center' }} className='subtitle'>Health at Every Size, Intuitive Eating, & Body Image</h4>
        <div
          className='workplace-trainings-section-content'
          style={{
            display: 'flex',
            width: '100%',
          }}>
          <img style={{ maxWidth: 400, maxHeight: 400, marginTop: 'auto', marginBottom: 'auto' }} src={watermelonLady} alt="" />
          <div style={{
            marginLeft: 25,
          }}>
            <p>
              Health at Every Size (HAES), Intuitive Eating, and Body Image work are essential components of a holistic and inclusive approach to health and well-being. So many struggle under the burden of
              cultural expectations of what their bodies mean about their worth- a troubling reality which can have global wellbeing effects. This work prioritizes individual health and wellness over
              arbitrary standards of weight or appearance, promoting a more sustainable and compassionate relationship with one's body and food.
            </p>
            <p style={{ marginTop: 20 }}>
              Learning how to work within these frameworks can offer providers access to the tools needed to address root causes of health disparities, empower individuals to trust their bodies,
              and equip healthcare professionals for more inclusive care, aligning with evidence-based practices for long-term health.            </p>
            <p style={{ marginTop: 20, textAlign: 'center' }}>
              <i>*Trainings typically include elements of didactic teaching, reflections, discussion, and live demonstration practice.
                Particular elements can be maximized or minimized by group preference.</i>
            </p>
          </div>
        </div>
      </Section>
      <ContactSect
        text={(
          <h4 style={{ fontWeight: 'normal' }}>
          Let’s build your better tomorrow <span className='breathing' style={{ fontSize: 60 }}>together</span>...
        </h4>
        )}
      />
    </div>
  );
};