import React from 'react';
import { Section } from '../components/Section';
import circle from '../imgs/new/circle.png';

interface Props {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
}
export const CircleDivider = (props: Props) => {
  const {
    title,
    subtitle,
    children
  } = props;
  return (
    <Section className='circle-divider'>
      <div className='circle-container'>
        <h3>{title}</h3>
        <h4>{subtitle}</h4>
        <img src={circle} alt="" />
      </div>
      <div style={{ zIndex: 10 }}>
        {children}
      </div>
    </Section>
  );
};