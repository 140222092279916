import React from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
// Needs webpd
import appIcon from "../imgs/new/logo.png";
import { NavLink, useLocation } from 'react-router-dom';
import ScrollToTop from './ScrollTop';
import { NavButton } from './NavButton';
import { NavMenu } from './NavMenu';

const navMenuItems = [
  {
    text: 'Home',
    to: '/'
  },
  {
    text: 'About',
    to: '/#about'
  },
  {
    text: 'Services',
    to: '/services',
    items: [
      {
        text: 'Therapy',
        to: '/services/therapy'
      },
      {
        text: 'Capacity Testing',
        to: '/services/capacity-testing'
      },
      {
        text: 'Workplace Training',
        to: '/services/workplace-training'
      },
      {
        text: 'The App',
        to: '/services/finding-center-app'
      }
    ]
  },
  {
    text: 'Contact',
    to: '#contact'
  }
];

export const HeaderBar = () => {
  const [size, setSize] = React.useState(window.innerWidth > 550 ? 'large' : 'small');

  const [servicesExpanded, setServicesExpanded] = React.useState(false);

  const handleWindowResize = React.useCallback(() => {
    const newSize = window.innerWidth > 550 ? 'large' : 'small';
    if (newSize !== size) {
      setSize(newSize);
    }
  }, [size]);

  const handleWindowClick = React.useCallback((e: MouseEvent) => {
    // This is gross..
    if (!(e.target as HTMLElement)?.classList.contains('d-menu-click') && servicesExpanded) {
      setServicesExpanded(false);
    }
  }, [servicesExpanded])

  const [shadow, setShadow] = React.useState(false);
  const handleWindowScroll = React.useCallback((e: any) => {
    if (!shadow && e.target.scrollTop > 0) {
      setShadow(true);
    } else if (shadow && e.target.scrollTop === 0) {
      setShadow(false);
    }
    if (servicesExpanded) {
      setServicesExpanded(false)
    }
  }, [shadow, servicesExpanded])

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    };
  }, [handleWindowResize]);

  React.useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick)
    };
  }, [handleWindowClick]);

  React.useEffect(() => {
    document.querySelector('.App')!.addEventListener('scroll', handleWindowScroll);
    return () => {
      document.querySelector('.App')!.removeEventListener('scroll', handleWindowScroll)
    };
  }, [handleWindowScroll]);

  const handleNavItemPressed = React.useCallback((text: string) => {
    if (text === 'Services') {
      setServicesExpanded(!servicesExpanded);
    }

    if (text === 'Contact') {
      console.log('Contact')
      document.getElementById('contact')?.scrollIntoView()
    }
  }, [servicesExpanded]);

  let Menu;

  if (size === 'small') {
    Menu = SmallNav;
  } else {
    Menu = LargeNav;
  }

  const classes = cn('header-bar', {
    '--shadow': shadow
  });

  const logoClasses = cn('logo-icon', {
    '--small': shadow
  });

  return (
    <div className={classes}>
      <ScrollToTop />
      <div className='header-logo-container'>
        <img
          className={logoClasses}
          src={appIcon}
          alt=""
        />
      </div>
      <Menu items={navMenuItems} onItemPressed={handleNavItemPressed} servicesExpanded={servicesExpanded} />
    </div>
  );
};

type NavItem = {
  text: string;
  to?: string;
  inline?: boolean;
  items?: NavItem[];
}

interface MenuProps {
  servicesExpanded?: boolean;
  items: NavItem[];
  onItemPressed(text: string): void;
}

type MenuItemProps = {
  expanded?: boolean;
  submenu?: boolean;
  onPress: ((text: string) => void);
  size: 'large' | 'small';
} & NavItem;

const MenuItem = (props: MenuItemProps) => {
  const {
    text,
    submenu,
    onPress,
    size,
    expanded,
    items,
    to,
  } = props;

  const location = useLocation();

  const classes = cn(`${size}-menu-item`, 'menu-item', {
    '--main-menu': !submenu,
    '--sub-menu': submenu,
  });

  const handlePress = () => {
    onPress(text);
  };

  let menu
  let content: any = <NavLink to={to ?? "/"} className={`nav-link --${text.toLowerCase()}`}>{text}</NavLink>;

  if (expanded && items?.length) {
    menu = <LargeMenu items={items} onPress={onPress} />;
  }

  if (items?.length) {
    content = text
  }

  const textStyle = cn("menu-item-text", {
    "--expanded": expanded,
    "--active": text === "Services" && location.pathname.includes("services"),
    "d-menu-click": text === "Services",
  })

  return (
    <div
      onClick={handlePress}
      className={classes}
    >
      <div className={textStyle}>
        {content}
      </div>
      {menu}
    </div>
  );
};

const SmallNav = (props: MenuProps) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const {
    items,
    onItemPressed
  } = props;

  const handleItemPressed = (text: string) => {
      setMenuOpen(false)
      onItemPressed(text);
  }

  return (
    <>
      <NavButton
        onPress={() => setMenuOpen(true)}
      />
      {createPortal(
      <NavMenu
        items={items}
        onItemPressed={handleItemPressed}
        onCloseButtonPress={() => setMenuOpen(false)}
        open={menuOpen}
      />,
      document.body
      )}
    </>
  );
};

const LargeNav = (props: MenuProps) => {
  const {
    items,
    onItemPressed,
    servicesExpanded
  } = props;

  const navItems = items.map(p => <MenuItem expanded={p.text === 'Services' && servicesExpanded} key={p.text} {...p} size='large' onPress={onItemPressed} />);

  return (
    <div className='large-nav'>
      {navItems}
    </div>
  );
};

const LargeMenu = (props: {
  items: NavItem[],
  onPress: (text: string) => void
}) => {

  const menuItems = props.items.map(item => (
    <div key={item.text} className="dropdown-menu-item menu-item-text d-menu-click">
      <NavLink to={item.to ?? "/"} className='nav-link d-menu-click'>
        {item.text}
      </NavLink>
    </div>
  ))
  return (
    <div
      className='dropdown-menu d-menu-click'>
      {menuItems}
    </div>
  );
};