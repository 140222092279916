import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const app = document.querySelector('.App')
    // const scrollTop = app?.scrollTop ?? 0
    let y = 0;
    // if (scrollTop > 0) {
    //   // For preventing header bar docking
    //   y = 2;
    // }
    app?.scrollTo(0, y);
  }, [pathname]);

  return null;
}