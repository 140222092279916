import { AppStoreButton } from "./AppStoreButton";
import { GooglePlayButton } from "./GooglePlayButton";

export const GetTheAppBar = (props: any) => {
  return (
    <div className="get-app-bar">
      <h3 style={{ marginRight: 50, fontWeight: 500 }}>The <span style={{ fontWeight: 700, fontStyle: "italic" }}>first</span> and <span style={{ fontWeight: 700, fontStyle: "italic" }}>only</span>  Intuitive Eating Class App!</h3>
      <div
        className="appstore-button-container"
        style={{
          display: "flex",
          // marginLeft: "auto"
        }}
      >
        <AppStoreButton />
        <GooglePlayButton />
      </div>
    </div>
  );
}