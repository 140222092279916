import appIcon from "../imgs/new/logo.png";
import facebook from '../imgs/facebookk.webp';
import insta from '../imgs/instagram.webp';
import youtube from '../imgs/youtube.webp';
import linkd from '../imgs/linkedin.webp';

import { SocialButton } from '../components/SocialButton';

import { Section } from '../components/Section';

import { Contact } from '../Contact';

export const ContactSect = (props: {
  text: any
}) => {
  return (
    <Section morePadding={true} className="contact-section" id="contact">
      <div className='app-icon-container' style={{ marginLeft: 'auto', marginRight: 'auto', width: 150 }}>
        <img src={appIcon} alt="" />
      </div>
      <div className='contact-section-form'>
        <div className='contact-form-column'>
          <div style={{
            fontSize: 30,
            fontWeight: 'normal',
            marginBottom: 25,
            textAlign: 'center'
          }}>
            {props.text}
          </div>
          <h4 style={{
            fontSize: 30,
            marginBottom: 50,
            fontWeight: 'normal',
            textAlign: 'center'
          }}>Reach out today!</h4>
          <a
            type="email"
            href="mailto:dr.smith@stephaniesmithpsyd.com"
            target="_blank"
            rel="noreferrer"
            className="button --secondary email-me"
            style={{
              display: "flex",
              backgroundColor: "unset",
              border: "1px solid black",
              height: 80,
              borderRadius: 40,
              alignItems: "center",
              textDecoration: "none",
              maxWidth: 500
            }}>
            <div
              style={{
                fontSize: 18,
                fontWeight: 700,
                marginRight: 15
              }}
            >
              EMAIL
            </div>
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              dr.smith@stephaniesmithpsyd.com
            </div>
          </a>
          <div
            className="button --secondary"
            style={{
              display: "flex",
              backgroundColor: "unset",
              border: "1px solid black",
              marginTop: 25,
              boxShadow: "none",
              alignItems: "center",
              height: 80,
              borderRadius: 40,
              cursor: "default",
              width: "100%",
              maxWidth: 500
            }}>
            <div
              style={{
                fontSize: 18,
                fontWeight: 700,
                marginRight: 15
              }}
            >
              SOCIAL
            </div>
            <div style={{
              display: "flex",
              marginLeft: "auto",
              marginRight: "auto",
            }}>
              <SocialButton
                image={facebook}
                to="https://www.facebook.com/stephaniesmithpsyd/"
              />
              <SocialButton
                image={insta}
                to="https://www.instagram.com/stephanie_smith_psyd/"
              />
              <SocialButton
                image={linkd}
                to="https://www.linkedin.com/in/stephaniemsmithpsyd"
              />
              <SocialButton
                image={youtube}
                to="https://www.youtube.com/@stephaniesmithpsy.d.9963"
              />
            </div>
          </div>
        </div>
        <div className='contact-form-column'>
          <Contact />
        </div>
      </div>
    </Section>
  );
};