export const Expected = (props: any) => {
  let secondary;

  if (props.secondaryText) {
    secondary = <p style={{ marginTop: 25 }}>{`${props.secondaryText}`}</p>;
  }
  return (
    <div className="individual-cell" style={{
      width: 300
    }}>
      <div className="individual-image" style={{ width: "auto" }}>
        <video
          style={{
            height: "100%",
            ...props.videoStyle
          }}
          src={props.video}
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}
        />
      </div>
      <div className="individual-text">
        <h4 className='playfair'>{props.title}</h4>
        <p>{props.text}</p>
        <i>{secondary}</i>
      </div>
    </div>
  );
};