import { LinkButton } from "./LinkButton";
import apple from '../imgs/apple.webp';

export const AppStoreButton = () => {
  return (
    <LinkButton
      style={{
        height: 55,
        width: 190,
        minWidth: 190,
        maxWidth: 190,
        borderRadius: 35
      }}
      className="app-button"
      text={(
        <div style={{
          display: "flex",
          alignItems: "center",
          textAlign: "left"
        }}>
          <div>
            <img src={apple} alt="" />
          </div>
          <div style={{
            marginLeft: 20
          }}>
            <div>Finding Center</div>
            <div style={{ fontWeight: 700, marginTop: 5 }}>App Store</div>
          </div>
        </div>
      )}
      to="https://apps.apple.com/us/app/finding-center/id1659352614"
      newTab={true}
      secondary={true}
    />
  );
};